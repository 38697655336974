html,
html[data-theme="light"] {
  --box-background-color: rgb(249, 249, 250);
  --highlight-color: hsl(0, 0%, 80%);
  --border-color: rgb(230, 230, 230);
  --link-color: rgb(0, 0, 238);
  --link-color-visited: rgb(85, 26, 139);
  --main-background-color: rgb(255, 255, 255);
  --main-text-color: rgb(51, 51, 51);
}

html[data-theme="dark"] {
  --box-background-color: hsl(0, 0%, 20%);
  --highlight-color: hsl(0, 0%, 50%);
  --border-color: hsl(0, 0%, 30%);
  --link-color: rgb(100, 210, 255);
  --link-color-visited: rgb(168, 125, 200);
  --main-background-color: rgb(28, 28, 30);
  --main-text-color: rgb(229, 229, 234);
}

@media (prefers-color-scheme: dark) {

  html,
  html[data-theme="dark"] {
    --box-background-color: hsl(0, 0%, 20%);
    --highlight-color: hsl(0, 0%, 50%);
    --border-color: hsl(0, 0%, 30%);
    --link-color: rgb(100, 210, 255);
    --link-color-visited: rgb(168, 125, 200);
    --main-background-color: rgb(28, 28, 30);
    --main-text-color: rgb(229, 229, 234);
  }

  html[data-theme="light"] {
    --box-background-color: rgb(249, 249, 250);
    --highlight-color: hsl(0, 0%, 80%);
    --border-color: rgb(230, 230, 230);
    --link-color: rgb(0, 0, 238);
    --link-color-visited: rgb(85, 26, 139);
    --main-background-color: rgb(255, 255, 255);
    --main-text-color: rgb(51, 51, 51);
  }
}

.header-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

/* Default to a one-column layout */
.header-grid {
  grid-template-columns: 1fr;
}

#title {
  grid-column: 1 / span 1;
}

#blurbimg {
  grid-column: 1 / span 1;
}

#blurb {
  grid-column: 1 / span 1;
}

/* Two-column layour for large screens */
@media only screen and (min-width: 768px) {
  .header-grid {
    grid-template-columns: 2fr 1fr;
  }

  #title {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  #blurb {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }

  #blurbimg {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

body {
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  margin: auto;
  padding: 10px;
  max-width: 1366px;
  min-width: 300px;
  line-height: 1.25;
  font-size: 120%;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-color);
  text-decoration-color: var(--link-color);
  text-decoration: underline
}

a:visited {
  color: var(--link-color-visited);
  text-decoration: none
}

table {
  width: 100%;
}

h2,
h3,
h4 {
  margin: 1em 0em;
}

nav {
  display: block;
}

p.note {
  font-size: 0.7em;
}

/* list items */
li {
  margin-bottom: 0.5em;
}

li p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* unordered lists */

ul.csv {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.csv li {
  display: inline;
}

ul.csv li:after {
  content: ", ";
}

ul.csv li:last-child:after {
  content: ".";
}

ul>li {
  list-style: none;
  position: relative;
}

ul.events {
  padding-left: 3em;
  list-style-position: outside;
}

ul.events>li:before {
  content: "\2022";
  /* render a bullet */
  position: absolute;
  left: -2.5em;
}

ul.paper-links {
  padding-left: 0em;
}

ul.paper-links:before {
  content: "["
}

ul.paper-links:after {
  content: "]"
}

ul.paper-links {
  display: inline;
  list-style: none;
}

ul.paper-links li {
  display: inline;
}

ul.paper-links li+li:before {
  content: ", ";
}

/* ordered lists */
ol {
  padding-left: 3em;
  list-style-position: outside;
}

ol.bibliography>li {
  list-style: none;
  position: relative;
}

ol.bibliography>li:before {
  font-weight: bold;
  counter-increment: list;
  content: "["attr(value) "]";
  position: absolute;
  left: -2.5em;
}

/* description lists */
dt {
  font-weight: bold;
}
dd {
  margin-left: 1.5em;
  margin-bottom: 1.5ex;
}
.arxivitem, 
.arxivcats {
  font-size: 0.83em;
}
.arxivtitle{
  font-weight: bold;
  font-size: 1.17em;
}

/* tables */
table {
  border-spacing: 0px;
}

td {
  padding: 0px;
  text-align: left;
  vertical-align: top;
}

th {
  padding: 0 1em 0 0;
  text-align: left;
  vertical-align: top;
  font-weight: bold;
}

code {
  white-space: pre-wrap;
}

.event-date {
  display: inline-block;
  vertical-align: top;
  width: 12em;
}

.event-details {
  display: inline;
}

.paper-journal {
  font-style: italic;
}

.paper-title {
  font-weight: bold;
}

.katex {
  font-size: 1em;
}

/* Style the tab */
nav {
  overflow: hidden;
  /* border: 1px solid var(--border-color); */
}

// Style links in a nav to look like buttons
nav a {
  background-color: var(--box-background-color);
  color: var(--main-text-color);
  float: left;
  border: none;
  outline: none;
  padding: 1ex 1em;
  font-size: large;
}

button {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

/* Style the buttons that are used to open the tab content */
nav button {
  background-color: var(--box-background-color);
  color: var(--main-text-color);
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1ex 1em;
  transition: 0.3s;
  font-size: large;
}

/* Change background color of buttons on hover */
nav button:hover {
  background-color: var(--highlight-color);
}

/* Create an active/current tablink class */
nav button.active {
  background-color: var(--highlight-color);
}

nav button:disabled {
  color: var(--highlight-color);
  background-color: var(--box-background-color);
}

/* Style the tab content */
.tabcontent {
  border: 1px solid var(--border-color);
  /* display: none; */
  padding: 1em 2em;
  /* border-top: none; */
  /* width: 100%; */

  visibility: hidden;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -1px
}

.displaytab {
  /* display: block; */
  visibility: visible;
  height: auto;
  overflow: visible;
  position: relative;
  top: auto;
}

.borderless {
  border: 0px;
  padding: 0px
}

iframe {
  width: 100%;
  height: 80vh;
}

.alert {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 1ex;
  border-radius: 4px;
}

.formunit {
  border: 1px solid var(--border-color);
  padding: 1ex;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr;
}

label {
  margin-right: 2ex;
}